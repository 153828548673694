<template>
  <div class="receipt">
    <div v-if='loading'>
      <DetailItem :detail='detail'></DetailItem>
    </div>
    <div v-else class="no-data">
        <img src="../assets/no-data.png" alt="">
    </div>

    <div class="btn-box" v-if='detail.receiptStatus == 0'>
      <div class="btn-inner">
        <div class="btn-item" @click="setReceiptStatus(1)">参会</div>
        <div class="btn-item btn-item1" @click="setReceiptStatus(2)">不参会</div>
      </div>
    </div>
    <div class="btn-box" v-if='detail.receiptStatus == 1 && detail.signInStatus == 0'>
        <div class="btn-inner">
            <div class="btn-item" @click="setSignInStatus(1)">立即签到</div>
        </div>
    </div>
    
    <SucPopup ref='sPop'></SucPopup>
  </div>
</template>

<script>
import { button, Toast, NavBar, Cell, CellGroup } from "vant";
import Cookies from "js-cookie";
import SucPopup from "@/components/SucPopup";
import DetailItem from "@/components/DetailItem";
export default {
  name: "index",
  data() {
    return {
        detail:{},
        reservationId:this.$route.query.reservationId,
        showSign:true,
        loading:false
    };
  },
  methods: {
    // 获取详情
    async scanCodeSignIn() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      let params = {
        openId: Cookies.get('openid'),
        reservationId:this.reservationId,
      };
      await this.$post(`/h5/weChat/scanCodeSignIn`, params).then(
        (res) => {
          if (res.code == "0") {
            Toast.clear()
            this.loading = true
            this.detail = res.result
          }else if(res.code == "501"){
            setTimeout(() => {
                this.$router.push({ path: "/binding" });
            }, 1000);
          }else if(res.code == "502"){
            Toast('您未被邀请参会');
            setTimeout(() => {
                Toast.clear()
                this.$router.push({ path: "/" });
            }, 1000);
          }else{
            Toast(res.message);
            setTimeout(() => {
                Toast.clear()
                this.$router.push({ path: "/" });
            }, 1000);
          }
        }
      );
    },
    // 参会
    async setReceiptStatus(receiptStatus) {
      let params = {
        receiptStatus: receiptStatus,
        conferenceUserId:this.detail.id,
      };
      await this.$post(`/h5/weChat/setReceiptStatus`, params).then(
        (res) => {
          if (res.code == "0") {
              Toast(res.message);
              this.scanCodeSignIn();
          }else{
            Toast(res.message);
          }
        }
      );
    },
    // 签到
    async setSignInStatus(signInStatus) {
      let params = {
        signInStatus: signInStatus,
        conferenceUserId:this.detail.id,
      };
      await this.$post(`/h5/weChat/setSignInStatus`, params).then(
        (res) => {
          if (res.code == "0") {
              this.$refs.sPop.open()
          }else{
            Toast(res.message);
          }
        }
      );
    },
  },
  mounted() {
    this.scanCodeSignIn()
  },
  components: {
    [Toast.name]: Toast,
    [button.name]: button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
    SucPopup,
    DetailItem,
  },
};
</script>

<style lang="scss" scoped>
.receipt {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
}

.btn-box {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  .btn-inner {
    display: flex;
    margin: 12px;

    .btn-item {
      flex: 1;
      font-size: 16px;
      color: #ffffff;
      background: linear-gradient(0deg, #526eff 0%, #6f8eff 100%);
      border-radius: 25px;
      // margin: 34rpx 30rpx;
      line-height: 50px;
      text-align: center;
    }
    .btn-item1 {
      background: linear-gradient(0deg, #d58526 0%, #e5a04d 100%);
      margin-left: 11px;
    }
  }
}
</style>